import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

import Github from '../img/github-icon.svg'
import Twitter from '../img/twitter-icon.svg'
import ProfileIcon from '../img/profile-icon.jpg'


export const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  const circleStyle = {
    borderRadius: '50%',
    width: '80px',
    height: '80px',
    objectFit: 'cover'
  };

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <div className="profile">
                <div className="profile-icon"><img src={ProfileIcon} alt="Profile con" style={circleStyle}/></div>
                <div className="profile-name">Shin<br />@fruitymidgnight</div>
              </div>
              <div className="social-link">
                <div className="twitter">
                  <a href="https://twitter.com/fruitymidnight" rel="noopener noreferrer"><img src={Twitter} alt="twitter-icon" style={{ width: '24px' }} /></a>                
                </div>  
                <div className="github">
                  <a href="https://github.com/fruitymidnight" rel="noopener noreferrer"><img src={Github} alt="gitub-icon" style={{ width: '24px' }} /></a>
                </div>
              </div>
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
